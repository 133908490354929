import React from "react";
import LinearReveal from "../../components/animation/LinearReveal";
import Layout from "../../components/Layout";
import Meta from "../../components/Meta";
import * as styles from "../../styles/product.module.css";
import * as heroStyles from "../../styles/hero-section.module.css";
import HeroSection from "../../components/HeroSection";

export default function Ats() {
  const application = [
    {
      imageSrc: "/img/products/ats/img-1.jpg",
      title: " Create New Clients with Email, Phone, Website and Location",
      description: "",
    },
    {
      imageSrc: "/img/products/ats/img-2.jpg",
      title:
        "Create Job posting choosing the client via lookup and enter details like minimum experience, maximum experience, salary range and skills required",
      description:
        "Skills This feature enables streamlined management of employee skills information. It helps the hiring team to assess whether the candidates possess the necessary skills for the job. It can be accessible through both PC and mobile platforms.",
    },
    {
      imageSrc: "/img/products/ats/img-3.jpg",
      title:
        "Skills, Resources, Submittals can be created using desktop as well as mobile",
      description:
        "The Job Posting feature allows access to job-related information and details and it helps job seekers to view open roles and apply to any (This feature is accessible through both our mobile app and PC platforms.)This object contains information like job title, description, location and other relevant details",
    },
    {
      imageSrc: "/img/products/ats/img-4.jpg",
      title:
        "Add multiple skills to a resource with start and end dates or experience in a single click.",
      description:
        "RESOURCE OBJECT This feature enables seamless management of candidate-related data. It educates recruiters,interviewers and hiring managers on best practice for interviewing and assessing candidates.",
    },
    {
      imageSrc: "/img/products/ats/img-5.jpg",
      title:
        "Get a detailed info on how many job postings are accepted, rejected or in progress with the help of reports",
      description:
        "The Skill Resource object contains essential information such as work experience, software proficiency, and rating level of the candidate. This object is connected to the Skill object and also to the Resource Object, providing a comprehensive overview of the candidate's skill set.",
    },
  ];
  return (
    <Layout>
      <Meta
        title="Applicant Tracking System - DBT"
        description="The hiring process can be time-consuming and frustrating experience for
            employers as well as job seekers. To streamline the process, Applicant
            Tracking System(ATS) is created"
      />
      <HeroSection
        title="Applicant Tracking System - DBT"
        titleClass={"text-primary " + heroStyles.heroTitle}
        imageClass={heroStyles.atsImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <article className={styles.article}>
        <LinearReveal>
          <p className={styles.description + " pt-5"}>
            The hiring process can be a time-consuming and frustrating experience for
            employers as well as job seekers. To streamline the process, Applicant
            Tracking System (ATS) is created
          </p>
          <p className={styles.description}>
            An applicant tracking system (ATS) is a software for recruiters and employers
            to track candidates throughout the recruiting and hiring process.
          </p>
          <p className={styles.description}>
            This includes Features such as job posting, Resource screening, interview
            Process and feedback Tracking, and applicant tracking. These features help the
            organisation find, hire and retain the best candidates.
          </p>
        </LinearReveal>
        {application.map((item, index) => (
          <LinearReveal key={index}>
            <div className={styles.description2}>
              <p>{item.title}</p>
              <p>{item.description}</p>
              <div className={"is-flex is-justify-content-center pt-5 "}>
                <img src={item.imageSrc} alt="DBT Timesheet" />
              </div>
            </div>
          </LinearReveal>
        ))}
      </article>
    </Layout>
  );
}
