// extracted by mini-css-extract-plugin
export var atsImage = "hero-section-module--atsImage--yLkqH";
export var blogImage = "hero-section-module--blogImage--sgV9N";
export var cicdIntegrationImage = "hero-section-module--cicdIntegrationImage--jUUEf";
export var dataEngineeringImage = "hero-section-module--dataEngineeringImage--lIlYL";
export var divClass = "hero-section-module--divClass--6uF2K";
export var heroData = "hero-section-module--heroData--34PM5";
export var heroDesc = "hero-section-module--heroDesc--G6Lns";
export var heroTitle = "hero-section-module--heroTitle--HWTeM";
export var salesforceImage = "hero-section-module--salesforceImage--2Seqt";
export var salesforceIntegrationImage = "hero-section-module--salesforceIntegrationImage--azfKO";
export var sapImage = "hero-section-module--sapImage--1moQe";
export var talentImage = "hero-section-module--talentImage--tiCpH";
export var timesheetImage = "hero-section-module--timesheetImage--5hsRF";