import React from "react";
import LinearReveal from "./animation/LinearReveal";

export default function HeroSection({
  divClass = "",
  imageClass = "",
  title = "",
  titleClass = "",
  titleStyle={maxWidth: '1000px', margin: 'auto'},
  description = "",
  descClass = "",
}) {
  return (
    <div className={divClass} style={{ height: "100vh" }}>
      <div className={imageClass}>
        <LinearReveal>
          <h1 className={titleClass} style={{...titleStyle}}>{title}</h1>
          {description && <p className={descClass}>{description}</p>}
        </LinearReveal>
      </div>
      <div id="section-ends"></div>
    </div>
  );
}
